<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">로그인</template>

            <div class="mw-580px mx-auto">
                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-text-field v-model="username" v-bind="{ ...attrs_input }" :dense="false" type="email" placeholder="아이디" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password" v-bind="{ ...attrs_input }" :dense="false" type="password" name="" value="" placeholder="비밀번호" @keydown.enter="login" />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="remember" v-bind="{ ...checkbox_primary, ...$attrs }" label="아이디저장" :value="true" />
                    </v-col>
                </v-row>

                <div class="btn-wrap">
                    <v-row class="row--sm">
                        <v-col cols="12">
                            <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="login">로그인하기</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <div class="divider-group text-center">
                                <ul class="grey--text">
                                    <li>
                                        <router-link to="/login/find-id/">아이디 찾기</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/login/find-password/">비밀번호 찾기</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/join">회원가입</router-link>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div class="btn-wrap btn-wrap--md">
                    <div class="v-divider--center">
                        <span class="v-divider__txt" style="white-space: nowrap">SNS로 간편 로그인하기</span>
                        <v-divider />
                    </div>
                    <div class="pt-20px pt-md-28px">
                        <v-row class="row--sm">
                            <v-col cols="12">
                                <kakao-login client_id="e1001b839381739ae753a777d9c3a965">카카오톡으로 계속하기</kakao-login>
                            </v-col>
                            <!-- <v-col cols="12">
                                <naver-login client_id="3F0hLX01jgfYpTLzzxO6">네이버로 계속하기</naver-login>
                            </v-col> -->
                            <!-- <v-col cols="12">
                                <v-btn block color="#1877F2" class="v-size--xx-large"> <i class="icon icon-facebook mr-auto" /><span class="mr-auto white--text">페이스북으로 계속하기</span> </v-btn>
                            </v-col> -->
                        </v-row>
                    </div>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { btn_primary, checkbox_primary } from "@/assets/variables";

import CryptoAES from "../../../plugins/crypto-aes";
import KakaoLogin from "../../../components/plugins/kakao/kakao-login.vue";
import NaverLogin from "../../../components/plugins/naver/naver-login.vue";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        KakaoLogin,
        NaverLogin,
        ClientPage,
        PageSection,
    },
    data: () => {
        return {
            btn_primary,
            checkbox_primary,

            username: "",
            password: "",

            remember: false,

            attrs_input,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            if (this.$cookies.get("password")) this.password = CryptoAES.decrypt(this.$cookies.get("password"));
        },
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.go(-1);
                this.$nextTick(() => this.$router.replace("/"));
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>
