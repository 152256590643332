<template>
    <v-btn v-bind:id="'kakao-login-btn'" block color="#FFEB00" class="v-size--xx-large" @click="login">
        <i class="icon icon-kakao mr-auto" /><span class="mr-auto"><slot default>카카오톡으로 계속하기</slot></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
    },
    computed: {
        redirect_uri: function () {
            return `${document.location.protocol}//${document.location.host}/oauth/kakao`;
        },
    },
    methods: {
        login() {
            Kakao.Auth.authorize({ redirectUri: this.redirect_uri });
        },
    },
};
</script>
