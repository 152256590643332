var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "id": 'kakao-login-btn',
      "block": "",
      "color": "#FFEB00"
    },
    on: {
      "click": _vm.login
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao mr-auto"
  }), _c('span', {
    staticClass: "mr-auto"
  }, [_vm._t("default", function () {
    return [_vm._v("카카오톡으로 계속하기")];
  }, {
    "default": ""
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }