var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "id": 'naver-login-button',
      "href": `https://nid.naver.com/oauth2.0/authorize?client_id=${_vm.client_id}&response_type=code&redirect_uri=${_vm.redirect_uri}`,
      "block": "",
      "color": "#00C73C"
    }
  }, [_c('i', {
    staticClass: "icon icon-naver mr-auto"
  }), _c('span', {
    staticClass: "mr-auto white--text"
  }, [_vm._t("default", function () {
    return [_vm._v("네이버 계정으로 로그인")];
  }, {
    "default": ""
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }